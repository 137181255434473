<script lang="ts">
	import type { Snippet } from "svelte";
	import { PillColor, PillSize } from "./PillProps.js";

	const { children, color, size }: { children: Snippet; color: PillColor; size: PillSize } = $props();
</script>

<span
	class={{
		"tracking-[0.02em] w-fit rounded-full flex items-center justify-center gap-2": true,
		"bg-white text-fstgrey-200": color === PillColor.White,
		"text-fstgrey-200 bg-fstlight-100": color === PillColor.Light,
		"py-1 sm:py-2 px-2 sm:px-4 text-xs sm:text-sm leading-normal": size === PillSize.Small,
		"text-xs leading-normal sm:text-sm xl:text-base py-1 sm:py-2 px-3 sm:px-4 xl:px-6 xl:py-[0.625rem]":
			size === PillSize.Medium,
		"": size === PillSize.Large,
	}}
>
	{@render children()}
</span>
