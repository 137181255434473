export enum PillColor {
	White,
	Light,
}

export enum PillSize {
	Small,
	Medium,
	Large,
}
